<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-space-between">
                    <div style="padding: 18px 12px">
                        <div class="text-2xl"><i class="mdi mdi-text-box-search-outline"></i> ຄົ້ນຫາພັດສະດຸ</div>
                    </div>
                    <v-breadcrumbs
                        class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        :items="$route.meta.breadcrumb"
                        divider=">"
                        large
                    />

                </div>
            </v-col>
        </v-row>

        <v-row >
            <v-col cols="12">
                <v-card class="p-2">
                    <div>
                        <v-alert
                            color="blue-grey lighten-4"
                            shaped
                            dense
                            class="text-center"
                        >
                            <strong>ຕິດຕາມພັດສະດຸ</strong>
                        </v-alert>
                    </div>
                    <v-form ref="formSearchData" class="pt-2" lazy-validation @submit.prevent="getSearchData()">
                        <v-text-field
                            outlined
                            label="ກະລຸນາປ້ອນເລກທີຕິດຕາມພັດສະດຸ ຫຼື ປ້ອນເບີໂທລະສັບ"
                            v-model="search"
                            :rules="[v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ']"
                            append-icon="mdi-barcode-scan"
                            dense
                            rounded
                        />
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="listdata.length > 0">
            <v-col cols="12" :lg="(isViewTracking) ? '7' : '12'" :xl="(isViewTracking) ? '8' : '12'">
                <v-card>
                    <v-data-table
                        :headers="headers"
                        :items="listdata"
                        :items-per-page="10"
                        class="elevation-1"
                        item-class="align-top"
                        :loading="$store.state.auth.loading2"
                        loading-text="ກຳລັງໂຫລດ...ກະລຸນາລໍຖ້າ"
                        fixed-header
                        height="530px"
                        :footer-props="{
                            'items-per-page-options': [10, 50, 100, -1],
                            showFirstLastPage: true,
                            'items-per-page-text': 'ຈຳນວນທີ່ສະແດງ:'
                        }"
                        dense
                    >
                        <template v-slot:item.option="{ item }">
                            <v-btn
                                class="mr-1 d-none d-lg-block d-xl-block"
                                color="primary"
                                fab
                                small
                                dark
                                text 
                                @click.stop="viewTracking(item)"
                            >
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>

                            <v-btn
                                class="mr-1 d-block d-lg-none d-xl-none"
                                color="primary"
                                fab
                                small
                                dark
                                text 
                                @click.stop="viewTrackingModal(item)"
                            >
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.date_log="{ item }">
                            {{ $helper.formatFontDate(item.date_log) }}
                        </template>
                        <template v-slot:item.pack_price="{ item }">
                            {{ $helper.formatNumber(item.pack_price, 0) }}
                        </template>
                        <template v-slot:item.to_cus_name="{ item }">
                            {{ item.to_cus_name + ' (' + item.to_cus_tel + ')' }}
                        </template>
                        <template v-slot:item.is_close="{ item }">
                            <v-chip
                                class="ma-2"
                                :color="(item.is_close) ? 'success white--text' : 'red white--text'"
                            >
                                {{ (item.is_close) ? 'ໄດ້ຮັບເຄື່ອງແລ້ວ' : 'ຍັງບໍ່ທັນໄດ້ຮັບເຄື່ອງ' }}
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
            <v-col cols="12" lg="5" xl="4" v-if="isViewTracking" class="d-none d-lg-block d-xl-block">
                <v-card>
                    <v-card-subtitle class="amber lighten-1 white--text">
                        <strong class="text-2xl black--text">ການເຄື່ອນໄຫວຂອງພັດສະດຸ</strong>
                        <div class="text-md black--text">{{ rec_data.track_code }}</div>
                        <!-- <div class="text-md black--text">{{ rec_data.pack_name }}</div> -->
                        <!-- <div class="text-sm black--text">{{ $helper.formatNumber(rec_data.pack_price, 0) }}</div> -->
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-subtitle>
                        <v-timeline
                            align-top
                            dense
                            clipped
                        >
                            <v-timeline-item 
                                fill-dot
                                v-for="(rows,i) in listtracking"
                                :key="i"
                                :color="rows.track_bg_color"
                            >
                                <template v-slot:icon>
                                    <v-icon :style="'color: ' + rows.track_color">{{ rows.track_icon }}</v-icon>
                                </template>
                                <strong class="text-xl black--text">{{ rows.track_desc }}</strong>
                                <div class="d-flex justify-start">
                                    <div><v-icon>mdi-calendar-outline</v-icon> {{ $helper.formatFontDate(rows.date_log) }}</div>
                                    <div><v-icon>mdi-clock-time-four-outline</v-icon> {{ $helper.formatFontTime(rows.date_log) }}</div>
                                </div>
                                <div class="green--text" v-if="rows.can_end">{{ 'ຣັບເຄື່ອງໂດຍ: ' + rows.cus_name + ' (' + rows.cus_tel + ')' }}</div>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="mdView"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-subtitle class="amber lighten-1 white--text pt-1">
                    <strong class="text-2xl black--text">ການເຄື່ອນໄຫວຂອງພັດສະດຸ</strong>
                    <div class="text-md black--text">{{ rec_data.track_code }}</div>
                    <!-- <div class="text-md black--text">{{ rec_data.pack_name }}</div> -->
                    <!-- <div class="text-sm black--text">{{ $helper.formatNumber(rec_data.pack_price, 0) }}</div> -->
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-subtitle>
                    <v-timeline
                        align-top
                        dense
                        clipped
                    >
                        <v-timeline-item 
                            fill-dot
                            v-for="(rows,i) in listtracking"
                            :key="i"
                            :color="rows.track_bg_color"
                        >
                            <template v-slot:icon>
                                <v-icon :style="'color: ' + rows.track_color">{{ rows.track_icon }}</v-icon>
                            </template>
                            <strong class="text-xl black--text">{{ rows.track_desc }}</strong>
                            <div class="d-flex justify-start">
                                <div><v-icon>mdi-calendar-outline</v-icon> {{ $helper.formatFontDate(rows.date_log) }}</div>
                                <div><v-icon>mdi-clock-time-four-outline</v-icon> {{ $helper.formatFontTime(rows.date_log) }}</div>
                            </div>
                            <div class="green--text" v-if="rows.can_end">{{ 'ຣັບເຄື່ອງໂດຍ: ' + rows.cus_name + ' (' + rows.cus_tel + ')' }}</div>
                        </v-timeline-item>
                    </v-timeline>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-subtitle class="pt-2 text-right">
                    <v-btn color="error" @click="mdView = false"><v-icon>mdi-close-circle-outline</v-icon> ປິດ</v-btn>
                </v-card-subtitle>
            </v-card>   
        </v-dialog> 
    </div>
</template>

<script>
    export default {
        data() {
            return {
                search: "",
                headers: [
                    {
                        text: 'ຕົວເລືອກ',
                        align: 'center',
                        sortable: false,
                        value: 'option',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ລຳດັບ',
                        align: 'center',
                        sortable: false,
                        value: 'rno',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ວັນທີ',
                        align: 'center',
                        sortable: false,
                        value: 'date_log',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ຂໍ້ມູນລູກຄ້າ',
                        align: 'center',
                        sortable: false,
                        value: 'to_cus_name',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ເລກທີຕິດຕາມພັດສະດຸ',
                        align: 'center',
                        sortable: false,
                        value: 'track_code',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ຊື່ພັດສະດຸ',
                        align: 'start',
                        sortable: false,
                        value: 'pack_name',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ລາຄາ',
                        align: 'end',
                        sortable: false,
                        value: 'pack_price',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ສະຖານະ',
                        align: 'center',
                        sortable: false,
                        value: 'is_close',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                ],
                listdata: [],
                listtracking: [],
                isViewTracking: false,
                track_code: '',
                rec_data: {},
                mdView: false,
            }
        },  
        methods: {
            async getSearchData(){
                this.listdata = await [];
                await this.$store.commit("auth/setLoading2", true);
                const result = await this.$store.dispatch("manage/getManagePackage", { action: "searchpackage", search: this.search });
                await this.$store.commit("auth/setLoading2", false);
                if(result.success){
                    this.listdata = await result.data;
                    this.isViewTracking = await false;
                }
            },
            async viewTracking(data){
                if(this.isViewTracking && data.track_code == this.rec_data.track_code){
                    this.isViewTracking = await false;
                }else{
                    this.rec_data = await data;
                    this.listtracking = await [];
                    await this.$store.commit("auth/setLoading", true);
                    const result = await this.$store.dispatch("manage/getManagePackage", { action: "trackpackage", code: this.rec_data.track_code });
                    await this.$store.commit("auth/setLoading", false);
                    if(result.success){
                        this.listtracking = await result.data;
                        this.isViewTracking = await true;
                    }
                }
            },
            async viewTrackingModal(data){
                this.mdView = await true;
                this.rec_data = await data;
                this.listtracking = await [];
                await this.$store.commit("auth/setLoading", true);
                const result = await this.$store.dispatch("manage/getManagePackage", { action: "trackpackage", code: this.rec_data.track_code });
                await this.$store.commit("auth/setLoading", false);
                if(result.success){
                    this.listtracking = await result.data;
                    this.isViewTracking = await true;
                }
            }
        }, 
    }
</script>